import useSWR, { mutate } from 'swr'
import { supabase } from '@/utils/supabase'
import { useProfile } from './useProfile'
import { Application, Externship } from '@/types'

async function fetchMyApplications(profile_id: number) {
  const { data, error } = await supabase
    .from('applications')
    .select(
      `
      id,
      student_id,
      externship:externship_id(
        id,
        type,
        alt_name,
        start_date, 
        end_date,
        start_date_2,
        complete,
        host:host_id(
          name,
          path,
          logo:logo_id(
            url
          )
        )
      )`
    )
    .eq('student_id', profile_id)
  if (error) throw error
  return (data || []) as Application[]
}

async function fetchMyExternships(profile_id: number) {
  const { data, error } = await supabase
    .from('externships')
    .select(
      `
      id,
      type,
      start_date, 
      end_date,
      start_date_2,
      host:hosts(
        name,
        path,
        logo:logo_id(
          url
        )
      ),
      profile:profile_id(
        id,
        first_name,
        last_name
      ),
      externship_profiles!inner(
        profile_id
      )
    `
    )
    .eq('externship_profiles.profile_id', profile_id)

  if (error) throw error
  // @ts-ignore
  return (data || []) as Externship[]
}

async function removeApplication(application_id: number) {
  const { data, error } = await supabase
    .from('applications')
    .delete()
    .eq('id', application_id)
    .select('id')
  mutate('/api/my-applications')
  if (error) throw error
  return data
}

export function useStudent() {
  const { profile } = useProfile()
  const { data: applications } = useSWR(
    profile?.id ? '/api/my-applications' : null,
    () => fetchMyApplications(profile?.id as number)
  )

  const { data: externships } = useSWR(
    profile?.id ? '/api/my-externships' : null,
    () => fetchMyExternships(profile?.id as number)
  )

  async function createApplication(externship_id: number) {
    const { data, error } = await supabase
      .from('applications')
      .insert({ student_id: profile?.id, externship_id })
      .select('id')
    mutate('/api/my-applications')
    if (error) throw error
    return data
  }

  return {
    applications,
    externships,
    removeApplication,
    createApplication,
  }
}
