import { useToast } from 'maker-ui/notifications'
import { useContext } from 'react'
import { useRouter } from 'next/navigation'
import * as Sentry from '@sentry/nextjs'

import { AuthContext } from '../components/AuthProvider'
import { UserType } from '@/types'
import { supabase } from '@/utils/supabase'

export function useAuth() {
  const { toast } = useToast()
  const { state, dispatch } = useContext(AuthContext)
  const router = useRouter()

  async function verifyOtp(email: string, token: string) {
    const { error } = await supabase.auth.verifyOtp({
      email,
      token,
      type: 'email',
    })

    if (error) {
      console.error(error)
      toast({ type: 'error', message: error.message })
      Sentry.captureException(error)
      Sentry.captureMessage(error.message)
    }

    return { success: !error, error }
  }

  async function login(
    email: string,
    userType?: UserType,
    attributes?: object
  ) {
    const { error } = await supabase.auth.signInWithOtp({
      email: email.toLowerCase(),
      options: {
        emailRedirectTo:
          process.env.NODE_ENV === 'production'
            ? 'https://delex.delbarton.org/account'
            : 'http://localhost:3000/account',
        shouldCreateUser: userType ? true : false,
        data: attributes
          ? attributes
          : userType
          ? {
              user_type: userType,
            }
          : undefined,
      },
    })

    if (error) {
      console.error(error)
      Sentry.captureException(error)
      Sentry.captureMessage(error.message)
      toast({
        type: 'error',
        message: error.message.includes('error saving new user')
          ? 'Error saving user'
          : 'User does not exist.',
      })
    } else {
      toast({ type: 'hint', message: 'Check your inbox for a login code.' })
    }

    return { success: !error, error }
  }

  async function logout() {
    const { error } = await supabase.auth.signOut()
    if (error) {
      toast({ type: 'error', message: error.message })
    } else {
      toast({ type: 'success', message: 'Successfully logged out.' })
      router.push('/')
    }
  }

  async function checkForUser(email: string): Promise<{
    exists: boolean
    userType?: UserType
  }> {
    const { data, error } = await supabase.rpc('can_register', {
      email_address: email,
    })

    return { exists: !!data, userType: data ? (data as UserType) : undefined }
  }

  async function getUser() {
    const {
      data: { user },
    } = await supabase.auth.getUser()
    if (user) {
      dispatch({ type: 'SET_USER', value: user })
    }
    return user
  }

  return {
    isLoggedIn: !!state.session,
    login,
    logout,
    verifyOtp,
    checkForUser,
    session: state.session,
    authUser: state.authUser,
    getUser,
    profile: state.profile,
    userType: state.userType,
  }
}
