import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import type { InputOption } from 'maker-ui/forms'
import { Externship } from '@/types'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

/**
 * Converts an application timestamp into a locale date string
 *
 * @param {string} date a date string
 * @returns {string} a formatted locale date string
 */
export function getFormalDate(d?: string | null) {
  if (!d) return
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const date = new Date(d)
  return date.toLocaleDateString('en-US', options)
}

export function getYearFromDate(dateString?: string | null) {
  if (!dateString) return null
  const date = new Date(dateString)
  const year = date.getFullYear()
  return year
}

export function formatYear(year: number | undefined | null): string | null {
  if (!year) {
    return null
  }

  const formattedYear = year % 100
  return formattedYear < 10 ? `'0${formattedYear}` : `'${formattedYear}`
}

export const years = (childGradYears = false) => {
  const now = new Date().getUTCFullYear() - 1
  const startDate = childGradYears ? now + 6 : now

  return Array(now - (now - 70))
    .fill('')
    .map((v, idx) => ({
      label: `${startDate - idx}`,
      value: `${startDate - idx}`,
    })) as InputOption[]
}

export const formatExternshipDate = (
  s?: string | null,
  type: 'INPERSON' | 'VIRTUAL' | null = 'INPERSON'
) => {
  if (!s) return undefined
  const date =
    type === 'INPERSON' ? dayjs.utc(s) : dayjs.utc(s).tz('America/New_York')
  return date
    .locale('en')
    .format(
      type === 'INPERSON' ? 'dddd, MMM D, YYYY' : `dddd, MMM D, YYYY h:mma EST`
    )
}

export function isTimeConflict(
  type: 'INPERSON' | 'VIRTUAL',
  start_date?: string,
  end_date?: string,
  externships: Externship[] = []
) {
  let conflicts: Externship[] = []
  if (!start_date) return conflicts

  const startDate = dayjs.utc(start_date)
  const endDate = end_date ? dayjs(end_date) : null

  externships.forEach((e) => {
    const externshipStartDate =
      e.type === 'INPERSON' ? dayjs.utc(e.start_date) : dayjs.utc(e.start_date)
    const externshipEndDate = e.end_date ? dayjs.utc(e.end_date) : null

    if (type === 'VIRTUAL' && e.type === 'INPERSON') {
      // Check for In-Person externship conflicts
      if (!externshipEndDate) {
        if (externshipStartDate.isSame(startDate, 'day')) {
          conflicts.push(e)
        }
      } else if (
        externshipEndDate &&
        startDate.isBetween(externshipStartDate, externshipEndDate, 'day', '[]')
      ) {
        {
          conflicts.push(e)
        }
      }
    }

    if (type === 'INPERSON') {
      // Check for In-Person and Virtual externship conflicts

      if (
        externshipStartDate.isSame(startDate, 'day') ||
        (externshipEndDate &&
          startDate.isBetween(
            externshipStartDate,
            externshipEndDate,
            'day',
            '[]'
          )) ||
        (endDate &&
          externshipEndDate &&
          endDate.isBetween(
            externshipStartDate,
            externshipEndDate,
            'day',
            '[]'
          ))
      ) {
        conflicts.push(e)
      }
    }
  })

  return conflicts
}
