export function formatParent(arr: string[]) {
  const years = arr.map((i) => `'${i.substring(2)}`)
  return years.join(' ')
}

export function isEmpty(obj?: object) {
  if (!obj) return true
  return Object.keys(obj).length === 0
}

/**
 * Parses the current path to extract an item's ID
 *
 * @param {string} path - the current NextJS `asPath` route
 * @returns {string} an ID string
 */
export function getId(path: string) {
  const p = path.split('/')
  return p[p.length - 1]
}

export function createSlug(title: string): string {
  return title
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // Remove non-word, non-space, non-hyphen characters
    .replace(/[\s_-]+/g, '-') // Replace spaces and underscores with hyphens
    .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
}

/**
 * Takes 2 objects and returns a list of keys with different values
 *
 * @param {T} values the new object
 * @param {T} initialValues the original object for comparison
 * @param {string[]} path the current object path (used for recursion)
 *
 * @returns string[]
 */
export function findDifferences<T>(
  values: T,
  initialValues: T,
  path: string[] = []
): string[] {
  const differences: string[] = []

  if (
    typeof values !== 'object' ||
    typeof initialValues !== 'object' ||
    values === null ||
    initialValues === null
  ) {
    return differences
  }
  //@ts-ignore
  const keys1 = Object.keys(values)
  //@ts-ignore
  const keys2 = Object.keys(initialValues)

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      differences.push([...path, key].join('.'))
      continue
    }

    const val1 = (values as any)[key]
    const val2 = (initialValues as any)[key]

    // Treat empty strings and undefined as equal
    const normalizedVal1 = val1 === '' ? undefined : val1
    const normalizedVal2 = val2 === '' ? undefined : val2

    if (Array.isArray(normalizedVal1) && Array.isArray(normalizedVal2)) {
      const arraysAreDifferent =
        normalizedVal1.length !== normalizedVal2.length ||
        normalizedVal1.some((value, index) => value !== normalizedVal2[index])

      if (arraysAreDifferent) {
        differences.push([...path, key].join('.'))
      }
    } else if (
      typeof normalizedVal1 === 'object' ||
      typeof normalizedVal2 === 'object'
    ) {
      differences.push(
        ...findDifferences(normalizedVal1, normalizedVal2, [...path, key])
      )
    } else if (normalizedVal1 !== normalizedVal2) {
      differences.push([...path, key].join('.'))
    }
  }

  return differences
}

export function containsAny(
  sourceArray: string[],
  targetArray: string[]
): boolean {
  return sourceArray.some((value) => targetArray.includes(value))
}

export const attireOptions = [
  { label: 'Business Formal (suit & tie)', value: 'business-formal' },
  { label: 'Business Casual', value: 'business-casual' },
  { label: 'Leisure', value: 'leisure' },
  { label: 'Athletic', value: 'athletic' },
  { label: 'Work Site (construction)', value: 'construction' },
]

export const formatAttire = (attire: string, getInitial?: boolean) => {
  const match = attireOptions.find((option) => option.value === attire)
  return getInitial ? match : match?.label
}
