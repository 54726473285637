'use client'

import { createContext, useEffect, useReducer } from 'react'
import { Profile, UserType } from '@/types'
import { supabase, User, Session } from '@/utils/supabase'

type Dispatch = (action: Action) => void
type Action =
  | {
      type: 'LOGOUT'
    }
  | {
      type: 'LOGIN'
      value: { authUser: User; session: Session }
    }
  | {
      type: 'SET_USER'
      value: User
    }
  | {
      type: 'SET_AUTH'
      value: {
        authUser?: User
        session?: Session
        profile?: Profile
        userType?: UserType
      }
    }
  | {
      type: 'REGISTER'
      value: User
    }

export interface AuthState {
  /** Current supabase auth user*/
  authUser?: User
  /** Current supabase auth session */
  session?: Session
  /** Profile */
  profile?: Profile
  /** User type */
  userType?: UserType
}

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthContext = createContext<{
  state: Partial<AuthState>
  dispatch: Dispatch
}>({ state: {}, dispatch: (a) => {} })

/**
 * The AuthProvider's state machine function for managing locally cached session data.
 *
 * @param {AuthState} state
 * @param {Action} action
 * @returns {AuthState}
 */
function authReducer(state: AuthState, action: Action): AuthState {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        authUser: action.value.authUser,
        session: action.value.session,
        profile: action.value.profile,
        userType: action.value.userType,
      }
    case 'SET_USER':
      return { ...state, authUser: action.value }
    case 'LOGIN':
      return {
        ...state,
        authUser: action.value.authUser,
        session: action.value.session,
      }
    case 'LOGOUT':
      return {
        ...state,
        authUser: undefined,
        session: undefined,
        userType: undefined,
        profile: undefined,
      }
    case 'REGISTER':
      return { ...state, authUser: action.value }
    default: {
      // @ts-ignore
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, {})

  async function getInitialSession() {
    const { data: session } = await supabase.auth.getSession()
    if (session?.session?.user) {
      const { data: profile } = await supabase
        .from('profiles')
        .select(`*, avatar: avatar_id(url)`)
        .eq('auth_id', session.session?.user.id)
        .maybeSingle()

      dispatch({
        type: 'SET_AUTH',
        value: {
          authUser: session?.session?.user ?? undefined,
          session: session.session ?? undefined,
          profile: (profile as Profile) ?? undefined,
          userType: (profile?.user_type as UserType) ?? undefined,
        },
      })
    }
  }

  /**
   * Listen for updates to user auth
   */
  useEffect(() => {
    // Subscribe to subsequent auth events
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event) => {
        if (
          event === 'SIGNED_IN' ||
          event === 'INITIAL_SESSION' ||
          event === 'TOKEN_REFRESHED'
        ) {
          getInitialSession()
        }
        if (event === 'SIGNED_OUT') {
          dispatch({
            type: 'SET_AUTH',
            value: {
              authUser: undefined,
              session: undefined,
            },
          })
        }
      }
    )

    return () => listener.subscription.unsubscribe()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
