import useSWR, { mutate } from 'swr'
import { AccountStatus, Application, Externship, Profile } from '@/types'
import { supabase } from '@/utils/supabase'
import { useAuth } from './useAuth'

async function getProfiles(type: 'STUDENT' | 'HOST' = 'HOST') {
  let query = supabase
    .from('profiles')
    .select(
      `
    id,
    first_name,
    last_name,
    email,
    account_status,
    grad_year,
    user_type,
    is_parent,
    child_grad_years,
    created_at,
    avatar:avatar_id(
      url
    )
    `
    )
    .eq('user_type', type)

  if (type === 'STUDENT') {
    query = query.eq('grad_year', '2025')
  }

  const { data, error } = await query

  if (error) throw error
  return data ?? []
}

async function getExternships() {
  const { data, error } = await supabase.from('externships').select(
    `
      id, 
      host:hosts(
        name,
        categories,
        path,
        logo:logo_id(
          url
        )
      ),
      type,
      attire,
      start_date,
      start_date_2,
      end_date,
      duration,
      start_time,
      end_time,
      min_students,
      max_students,
      complete,
      address,
      profile:profile_id(
        id,
        first_name,
        last_name
      )
  `
  )

  if (error) throw error
  return data ?? []
}

async function approveHost(id: number, decision: AccountStatus) {
  const { data, error } = await supabase
    .from('profiles')
    .update({ account_status: decision })
    .eq('id', id)
    .select('id')
  if (error) throw error
  return data
}

async function getExternshipHosts() {
  const { data, error } = await supabase
    .from('externships')
    .select(
      `
      id,
      complete,
      profile:profile_id(
        id,
        first_name,
        last_name,
        email
      ),
      host:hosts(
        name
      )
    `
    )
    .eq('complete', false)

  if (error) throw error
  return data ?? []
}

async function getApplications() {
  const { data, error } = await supabase.from('applications').select(`
    id,
    assigned,
    externship:externships(
      id,
      start_date,
      end_date,
      type,
      max_students,
      min_students,
      address,
      host:hosts(
        name,
        path,
        logo:logo_id(
          url
        )
      )
    ),
    profile:profiles(
      id,
      first_name,
      last_name,
      email
    )
  `)

  if (error) throw error
  return data ?? []
}

async function getNotifications() {
  const { data, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('message_type', 'ASSIGNMENT')

  if (error) throw error
  return data ?? []
}

interface ConfirmationProps {
  profile_id: number
  externship_id: number
  admin_id: number
  recipient_email: string
}

async function sendConfirmation(props: ConfirmationProps) {
  const { data, error } = await supabase
    .from('notifications')
    .insert({ ...props, message_type: 'ASSIGNMENT', channel: 'EMAIL' })
    .select('id')
    .single()

  if (error) throw error
  return data
}

export interface UpdateApplicationProps {
  assigned: boolean | null
  application: Application
}

async function updateApplication({
  assigned,
  application,
}: UpdateApplicationProps) {
  // First update application `assigned` status
  const { data, error } = await supabase
    .from('applications')
    .update({ assigned })
    .eq('id', application.id!)
    .select('id')
    .single()

  if (assigned === true) {
    // Create Externship Profile
    const { data, error } = await supabase.from('externship_profiles').insert({
      profile_id: application.profile?.id!,
      externship_id: application.externship?.id!,
      profile_type: 'STUDENT',
    })
    if (error) throw error
  } else if (
    (assigned === false || assigned === null) &&
    application.assigned === true
  ) {
    // Delete Externship Profile if it exists
    const { data, error } = await supabase
      .from('externship_profiles')
      .delete()
      .eq('profile_id', application.profile?.id!)
      .eq('externship_id', application.externship?.id!)
    if (error) throw error
  }

  if (error) throw error
  mutate('/api/admin-applications')
  return data
}

export function useAdmin() {
  const { profile } = useAuth()
  const isAdmin = profile?.user_type === 'ADMIN'

  const { data: hosts } = useSWR(isAdmin ? '/api/admin-hosts' : null, () =>
    getProfiles()
  )

  const { data: students } = useSWR(
    isAdmin ? '/api/admin-students' : null,
    () => getProfiles('STUDENT')
  )

  const { data: externships } = useSWR(
    isAdmin ? '/api/admin-externships' : null,
    () => getExternships()
  )

  const { data: notifications } = useSWR(
    isAdmin ? '/api/admin-notifications' : null,
    () => getNotifications()
  )

  const { data: externshipHosts } = useSWR(
    isAdmin ? '/api/admin-externship-hosts' : null,
    () => getExternshipHosts()
  )

  const { data: applications } = useSWR(
    isAdmin ? '/api/admin-applications' : null,
    () => getApplications()
  )

  return {
    applications: (applications || []) as Application[],
    hosts: (hosts || []) as Profile[],
    externships: (externships || []) as Externship[],
    approveHost,
    notifications,
    externshipHosts,
    sendConfirmation,
    updateApplication,
    students: (students || []) as Profile[],
  }
}
