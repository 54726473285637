import useSWR from 'swr'
import { useEffect } from 'react'
import { Externship, Host, Profile } from '@/types'
import { supabase } from '@/utils/supabase'
import { useAuth } from './useAuth'
import { updateProfile, createOrUpdateHost } from './requests'

async function fetchMyProfile(authId: string) {
  const { data, error } = await supabase
    .from('profiles')
    .select(
      `
      id, 
      email,
      auth_id,
      first_name,
      last_name,
      user_type,
      account_status,
      grad_year,
      is_parent,
      child_grad_years,
      job_position,
      job_description,
      links, 
      avatar:avatar_id(
        url
      ),
      hosts(
        id
      )
      `
    )
    .eq('auth_id', authId)
    .single()
  if (error) throw error
  return data as Profile
}

async function fetchMyCompany(id: number) {
  const { data, error } = await supabase
    .from('hosts')
    .select(
      `
      id,
      name,
      description,
      logo:logo_id(
        url
      ),
      website,
      categories,
      host_profiles!inner(
        profile_id
      )
      `
    )
    .eq('host_profiles.profile_id', id)
    .maybeSingle()
  if (error) throw error
  return data ? (data as Host) : undefined
}

async function fetchMyExternships(id: number) {
  const { data, error } = await supabase
    .from('externships')
    .select(
      `
      *, 
      host:hosts(
        id,
        name,
        description,
        website,
        categories,
        path,
        logo:logo_id(
          url
        )
      ),
      externship_profiles!inner(
        profile_id
      )
  `
    )
    .eq('externship_profiles.profile_id', id)
  if (error) throw error
  return (data ?? []) as unknown as Externship[]
}

/**
 * Hook for viewing and managing the user's data
 */
export function useProfile() {
  const { authUser, getUser } = useAuth()
  const id = authUser?.id

  useEffect(() => {
    // Refresh the user on mount
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: profile } = useSWR(id ? '/api/my-profile' : null, () =>
    fetchMyProfile(id!)
  )

  const { data: host } = useSWR(
    //@ts-ignore
    profile?.id && profile?.user_type === 'HOST' && profile?.hosts?.length
      ? '/api/my-company'
      : null,
    () => fetchMyCompany(profile?.id!)
  )

  const { data: externships } = useSWR(
    profile?.id && profile?.user_type === 'HOST' ? '/api/my-externships' : null,
    () => fetchMyExternships(profile?.id!)
  )

  return {
    profile,
    updateProfile,
    createOrUpdateHost,
    host,
    externships: externships || [],
  }
}
