import { generateId } from 'maker-ui/utils'
import { Externship, Host, Profile } from '@/types'
import { createSlug } from '@/utils/helper'
import { supabase } from '@/utils/supabase'
import { ExternshipConfirmation } from '../app/api/confirmation/route'
import { formatExternshipDate } from '@/utils/dates'

export async function createOrUpdateHost(host: Host, profileId?: number) {
  if (!profileId && !host) return false
  // Create host
  if (!host.id && profileId && host.name) {
    const slug = createSlug(host.name)
    // Check for unique slug
    const { data: existingSlugs } = await supabase
      .from('hosts')
      .select('id')
      .eq('path', slug)

    const { data, error } = await supabase
      .from('hosts')
      .insert({
        ...host,
        path: existingSlugs?.length ? `${slug}-${generateId(3)}` : slug,
      })
      .select('id')
      .single()

    if (error) throw error
    // Then create the Host Profile
    const { error: hostProfileError } = await supabase
      .from('host_profiles')
      .insert({
        host_id: data?.id,
        profile_id: profileId,
      })

    if (hostProfileError) throw hostProfileError
    return data
  } else {
    if (!host.id) return
    // Update host
    const { data, error } = await supabase
      .from('hosts')
      .update(host)
      .eq('id', host.id)
      .select('id')
      .single()
    if (error) throw error
    return data && !error ? data : undefined
  }
}

export async function createOrUpdateExternship(
  externship: Externship,
  profileId?: number,
  user?: {
    name: string
    email: string
    company_name: string
  }
) {
  if (!externship && !profileId) return

  // Create externship
  if (!externship.id && externship && profileId) {
    const { data, error } = await supabase
      .from('externships')
      .insert(externship)
      .select('id')
      .single()

    // Send externship confirmation
    const mailer: Partial<ExternshipConfirmation> = {
      type: externship.type!,
      name: user?.name!,
      email: user?.email!,
      start_date: formatExternshipDate(externship.start_date, externship.type),
      end_date: formatExternshipDate(externship.end_date, externship.type),
      start_time: externship.start_time!,
      end_time: externship.end_time!,
      min_students: externship.min_students!,
      max_students: externship.max_students!,
      company_name: user?.company_name!,
      attire: externship?.attire!,
      address: externship.address?.address_1!,
      city: externship.address?.city,
      state: externship.address?.state,
      postal_code: externship.address?.postal_code!,
    }

    // Then create the Externship Profile
    const { data: hostProfile, error: hostProfileError } = await supabase
      .from('externship_profiles')
      .insert({
        externship_id: data?.id,
        profile_id: profileId,
        profile_type: 'HOST',
      })

    if (error || hostProfileError) {
      console.error('Error creating externship', error, hostProfileError)
      return false
    }

    try {
      const data = await fetch('/api/confirmation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mailer),
      }).then((res) => res.json())

      console.log('data is', data)
    } catch (error) {
      console.error('Error sending confirmation email', error)
    }

    return data ? data : false
  } else {
    if (!externship.id) return
    // Update externship
    const { data, error } = await supabase
      .from('externships')
      .update(externship)
      .eq('id', externship.id)
      .select('id')
    if (error) {
      return false
    }
    return data ? data : false
  }
}

export async function updateProfile({ id, ...profile }: Profile) {
  const { data, error } = await supabase
    .from('profiles')
    .update(profile)
    .eq('id', id as number)
    .select()
  if (error) throw error
  return data && !error ? data : false
}
