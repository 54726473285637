import type { Externship, ExternshipMessage, Host, Profile } from '@/types'
import useSWR, { mutate } from 'swr'
import { supabase } from '@/utils/supabase'
import { createOrUpdateExternship } from './requests'

async function fetchAllExternships() {
  const { data, error } = await supabase
    .from('externships')
    .select('id, type, start_date')
    .not('complete', 'eq', true)
  if (error) throw error
  return data
}

export async function getExternshipById(id: number) {
  const { data, error } = await supabase
    .from('externships')
    .select('*')
    .eq('id', id)
    .maybeSingle()
  if (error) throw error
  return data as Externship
}

async function getExternshipMessages(externshipId: number) {
  const { data, error } = await supabase
    .from('externship_messages')
    .select(
      `
      id,
      title,
      body,
      type,
      created_at,
      owner_id,
      externship_id,
      profile:profile_id (
        first_name,
        last_name
      )
    `
    )
    .eq('externship_id', externshipId)
  if (error) throw error
  return (data || []) as ExternshipMessage[]
}

export interface CreateMessageProps {
  type?: 'announcement' | 'question'
  externship_id?: number
  profile_id?: number
  owner_id?: string
  title?: string
  body?: string
}

async function createMessage(props: CreateMessageProps) {
  const { data, error } = await supabase
    .from('externship_messages')
    .insert(props)
    .select('id')
    .maybeSingle()
  if (error) throw error
  mutate(`/api/externship-messages?id=${props.externship_id}`)
  return data
}

async function deleteMessage(id: number) {
  const { data, error } = await supabase
    .from('externship_messages')
    .delete()
    .eq('id', id)
    .select('id, externship_id')
    .single()
  if (error) throw error
  mutate(`/api/externship-messages?id=${data.externship_id}`)
  return data
}

async function getExternshipUsers(externshipId: number) {
  const { data, error } = await supabase
    .from('externship_profiles')
    .select(
      `
      externship_id,
      profile:profile_id (
        id,
        first_name,
        last_name,
        email,
        phone,
        user_type,
        child_grad_years,
        grad_year,
        avatar: avatar_id(
          url
        )
      ),
      profile_type
    `
    )
    .eq('externship_id', externshipId)

  if (error) throw error
  return (data?.map(({ profile }) => ({ ...profile })) || []) as Profile[]
}

async function getHostById(id: number) {
  const { data, error } = await supabase
    .from('hosts')
    .select('*, logo:logo_id(url)')
    .eq('id', id)
    .maybeSingle()
  if (error) throw error
  return data
}

async function getProfileById(id: number) {
  const { data, error } = await supabase
    .from('profiles')
    .select('id, last_name, job_description, job_position')
    .eq('id', id)
    .maybeSingle()
  if (error) throw error
  return data
}

export function useExternship(id?: number) {
  const { data: allExternships } = useSWR('/api/all-externship-dates', () =>
    fetchAllExternships()
  )

  const { data: getExternship } = useSWR(
    id ? `/api/externship-by-id?=${id}` : null,
    () => getExternshipById(id!) as Externship | undefined
  )

  const { data: getExternshipHost } = useSWR(
    getExternship?.host_id
      ? `/api/host-by-id?=${getExternship?.host_id}`
      : null,
    () => getHostById(getExternship?.host_id!) as Host | undefined
  )

  const { data: messages } = useSWR(
    id ? `/api/externship-messages?id=${id}` : null,
    () => getExternshipMessages(id!)
  )

  const { data: externshipUsers } = useSWR(
    getExternship?.id ? `/api/externship-users?id=${getExternship?.id}` : null,
    () => getExternshipUsers(getExternship?.id!)
  )

  const { data: getExternshipProfile } = useSWR(
    getExternship?.profile_id
      ? `/api/profile-by-id?=${getExternship?.profile_id}`
      : null,
    () => getProfileById(getExternship?.profile_id!) as Profile | undefined
  )

  return {
    allExternships,
    getExternship,
    externshipUsers,
    createOrUpdateExternship,
    getExternshipHost,
    getExternshipProfile,
    messages: (messages as ExternshipMessage[]) || [],
    createMessage,
    deleteMessage,
  }
}
